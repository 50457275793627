// Generated by Framer (a7ecdf4)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Iconoir } from "https://framerusercontent.com/modules/zL9598C4KbEbqUGvSR14/rI8sPHpnG9XGcCPc0vU4/Iconoir.js";
const IconoirFonts = getFonts(Iconoir);

const enabledGestures = {tVfodwbc8: {hover: true}};

const cycleOrder = ["tVfodwbc8"];

const serializationHash = "framer-IzclO"

const variantClassNames = {tVfodwbc8: "framer-v-jq46au"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tVfodwbc8", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-jq46au", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"tVfodwbc8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80, ...style}} {...addPropertyOverrides({"tVfodwbc8-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1guffrw"} layoutDependency={layoutDependency} layoutId={"t3f1qtFfD"} style={{backgroundColor: "rgb(10, 133, 255)"}}/><motion.div className={"framer-4tit9l-container"} layoutDependency={layoutDependency} layoutId={"z8y5qdpqY-container"}><Iconoir color={"rgb(0, 0, 0)"} height={"100%"} iconSearch={"Home"} iconSelection={"Plus"} id={"z8y5qdpqY"} layoutId={"z8y5qdpqY"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"tVfodwbc8-hover": {color: "rgb(255, 255, 255)"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-IzclO[data-border=\"true\"]::after, .framer-IzclO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IzclO.framer-pma8fu, .framer-IzclO .framer-pma8fu { display: block; }", ".framer-IzclO.framer-jq46au { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 50px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 50px; will-change: var(--framer-will-change-override, transform); }", ".framer-IzclO .framer-1guffrw { bottom: -14px; flex: none; height: 14px; left: 0px; overflow: hidden; position: absolute; right: 0px; z-index: 1; }", ".framer-IzclO .framer-4tit9l-container { flex: none; height: 36px; position: relative; width: 36px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IzclO.framer-jq46au { gap: 0px; } .framer-IzclO.framer-jq46au > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-IzclO.framer-jq46au > :first-child { margin-left: 0px; } .framer-IzclO.framer-jq46au > :last-child { margin-right: 0px; } }", ".framer-IzclO.framer-v-jq46au.hover.framer-jq46au { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 50px); }", ".framer-IzclO.framer-v-jq46au.hover .framer-1guffrw { height: unset; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"yX_IXZl2p":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerihWk_h_tp: React.ComponentType<Props> = withCSS(Component, css, "framer-IzclO") as typeof Component;
export default FramerihWk_h_tp;

FramerihWk_h_tp.displayName = "Service Button";

FramerihWk_h_tp.defaultProps = {height: 50, width: 50};

addFonts(FramerihWk_h_tp, [...IconoirFonts])